import * as React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'gatsby';

import saveIndexData from '../../data/save/index.json';
import { DefaultCollapsibleMarkdownSection, InformationLayout } from '../../components';

export const SaveIndexPageInternal = ({ mainMarkdown, header, checking, savings, certificates, iras }: typeof saveIndexData) => {
  return (
    <InformationLayout title={header} mainMarkdown={mainMarkdown} header={header}>
      <div className="child-spacing-y-3">
        <DefaultCollapsibleMarkdownSection {...checking} />
        <DefaultCollapsibleMarkdownSection {...savings} />
        <DefaultCollapsibleMarkdownSection {...certificates} />
        <DefaultCollapsibleMarkdownSection {...iras} />

        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-row child-spacing-x-3">
            <Button size="lg" tag={Link} to="/rates/#savings" color="primary">Savings Rates</Button>
          </div>
        </div>
      </div>
    </InformationLayout>
  );
};

export default () => <SaveIndexPageInternal {...saveIndexData} />;
